export default function Quote({ blok }) {
  return (
    <div className="flex flex-wrap items-center justify-center my-24 md:my-32">
      <div className="md:w-6/12 md:pr-8 lg:pr-24 mt-5 flex flex-col text-center md:text-left">
        <span className="text-7xl leading-none whitespace-nowrap not-sr-only lg:-mt-10 !leading-[0]">
          “
        </span>
        <p className="!text-base pt-2 !mt-0 lg:pt-0">{blok.quote}</p>
        {blok?.name && (
          <strong className="text-xl lg:text-2xl text-white block font-medium mb-2">
            {blok.name}
          </strong>
        )}
        {blok?.function && (
          <span className="text-xs uppercase">{blok.function}</span>
        )}
      </div>
      <div className="w-3/12 mt-4 md:mt-0 md:w-2/12">
        {blok?.photo && (
          <img
            className="w-32 md:w-full rounded-full"
            src={blok?.photo?.filename}
            alt={blok?.photo?.alt}
          />
        )}
      </div>
    </div>
  );
}
